import { useNavigate, useParams } from "react-router-dom"
import { useAppDispatch } from "../../../store/app/hooks"
import { useEffect, useState } from "react"
import { getErrorsAsString } from "../../../utils/getErrorsAsString"
import { setOpenSnackbar } from "../../../store/features/snackbar/snackbarSlice"
import {
  NewFileInput,
  UpdateUserInput,
  UserRoleType,
} from "../../../gql/graphql"
import { graphql } from "../../../gql"
import { useLazyQuery, useMutation } from "@apollo/client"
import { updateCacheAdmin } from "../../../caches/updateCacheAdmin"
import { uploadFile } from "../../../utils/uploadFile"
import FormAdmin from "../FormAdmin/FormAdmin"
import Loader from "../../Common/Loader/Loader"
import { MinTypeFile } from "../../Common/CustomUploadFiles/CustomUploadFiles"
import { InfinityListItem } from "../../../types/InfinityListItem"
import { isSuperAdmin } from "../../../utils/permissions"
import { getUserCountryId } from "../../../utils/authToken"

const updateAdminMutation = graphql(`
  mutation UpdateAdmin($updateUserInput: UpdateUserInput!) {
    updateUserAccount(updateUserInput: $updateUserInput) {
      token
      user {
        id
        phoneNumber
        email
        role
        name
        lastName
        permissions
        isActiveAdmin
        ProfilePhoto {
          url
          id
          name
        }
        country {
          id
          name_fr
        }
      }
    }
  }
`)

const getOneAdminQuery = graphql(`
  query getOneAdminQuery($where: UserWhereUniqueInput!) {
    user(where: $where) {
      id
      phoneNumber
      email
      role
      name
      lastName
      lang
      permissions
      isActiveAdmin
      ProfilePhoto {
        id
        url
        name
      }
      country {
        id
        name_fr
      }
    }
  }
`)

export default function EditAdmin() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const params = useParams()

  const id = params.id ? parseInt(params.id, 10) : 0

  const [updateadmin, { loading }] = useMutation(updateAdminMutation)
  const [isLoading, setIsLoading] = useState(false)
  const [file, setFile] = useState<File | null>(null)
  const [rePassword, setRePassword] = useState<string>("")
  const [isActiveAdmin, setIsActiveAdmin] = useState(true)
  const [defaulPhoto, setDefaulPhoto] = useState<MinTypeFile | undefined>(
    undefined,
  )
  const [profileIdDelete, setProfileIdDelete] = useState<number | undefined>(
    undefined,
  )

  const [permissions, setPermissions] = useState<string[]>([])

  const defaultValue: InfinityListItem = {
    label: "",
    value: "",
  }
  const [country, setCountry] = useState<InfinityListItem>(defaultValue)

  const [user, setUser] = useState<UpdateUserInput>({
    id,
    email: "",
    lastName: "",
    role: UserRoleType.Admin,
    phoneNumber: "",
    name: "",
    lang: "fr",
  })

  const [getAdmin, { loading: loadingInit, error }] = useLazyQuery(
    getOneAdminQuery,
    {
      onCompleted(data) {
        const newUser = data.user
        if (!isSuperAdmin()) {
          if (newUser?.country?.id != getUserCountryId()) {
            navigate("/")
            return
          }
        }
        setUser({
          id: newUser?.id || 0,
          email: newUser?.email || "",
          lastName: newUser?.lastName || "",
          phoneNumber: newUser?.phoneNumber || "",
          name: newUser?.name || "",
          lang: newUser?.lang || "fr",
          role: UserRoleType.Admin,
        })
        setIsActiveAdmin(Boolean(newUser?.isActiveAdmin))
        const permissions = newUser?.permissions ? newUser?.permissions : []
        setPermissions([...permissions])
        if (data.user?.ProfilePhoto) {
          const { url, id, name } = data.user?.ProfilePhoto
          setDefaulPhoto({ url, id, name })
        }
        if (newUser?.country) {
          const newcountry: InfinityListItem = {
            value: newUser?.country.id.toString() || "",
            label: newUser?.country.name_fr || "",
          }
          setCountry(newcountry)
        }
      },
    },
  )

  const handleInputChange = (event: any) => {
    setUser({
      ...user,
      [event.target.name]: event.target.value,
    })
  }

  const handleSignup = (profilePhoto?: NewFileInput) => {
    updateadmin({
      variables: {
        updateUserInput: {
          ...user,
          id,
          permissions,
          profilePhoto: profilePhoto,
          profilePhotoDeleteId: profileIdDelete,
          countryId: Number(country.value),
          isActiveAdmin,
        },
      },
      onError: (error) => {
        const message = getErrorsAsString(error)
        dispatch(setOpenSnackbar({ message }))
        setIsLoading(false)
      },
      onCompleted: () => {
        setIsLoading(false)
        navigate("/admin/list")
      },
      update: (cache, { data }) => {
        updateCacheAdmin({
          action: "update",
          cache,
          entryData: data?.updateUserAccount.user,
        })
      },
    })
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    if (file) {
      setIsLoading(true)
      uploadFile({
        cbError: ({ message }) => {
          setOpenSnackbar({
            message,
          })
          setIsLoading(false)
        },
        cbOk: (file) => {
          handleSignup(file)
        },
        file,
        getPrecent: (value) => {},
      })
    } else {
      handleSignup()
    }
  }

  const onFileUpload = (file: File) => {
    setFile(file)
  }

  const onClearUpload = (file: MinTypeFile) => {
    setFile(null)
    if (file.id) {
      setProfileIdDelete(file.id)
    }
  }

  useEffect(() => {
    getAdmin({
      variables: {
        where: {
          id,
        },
      },
    })
  }, [id])

  const getCountry = (country: InfinityListItem) => {
    setCountry(country)
  }

  const toogleActive = () => setIsActiveAdmin((prev) => !prev)

  if (loadingInit) return <Loader />

  if (error) return "error"

  return (
    <FormAdmin
      getPhoneNumber={(value) => setUser({ ...user, phoneNumber: value })}
      getRePassword={(value) => setRePassword(value)}
      handleInputChange={handleInputChange}
      handleSubmit={handleSubmit}
      loading={isLoading || loading}
      onClearUpload={onClearUpload}
      onFileUpload={onFileUpload}
      rePassword={rePassword}
      title={"Modifier un admin"}
      email={user.email || ""}
      lastName={user.lastName || ""}
      name={user.name || ""}
      password={""}
      phoneNumber={user.phoneNumber || ""}
      defaulPhoto={defaulPhoto}
      havePassword={false}
      handlePermissions={setPermissions}
      permissions={permissions}
      getCountry={getCountry}
      countryDefault={country}
      isActiveAdmin={isActiveAdmin}
      toogleActive={toogleActive}
    />
  )
}
